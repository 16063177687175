import React from 'react';
import { makeStyles } from '@material-ui/styles';
import theme from '../../styles';
import { Grid } from '@material-ui/core';
import heroPhone from '../../resources/images/hero-phone.png';
import background from '../../resources/images/background/dual-wave.svg';
import ball1 from '../../resources/images/background/ball-secondary.svg';
import ball2 from '../../resources/images/background/ball-primary.svg';
import { motion } from 'framer-motion';
import growTransition from '../transition';

const useStyles = makeStyles({
  container: {
    ...theme.globals.containerYCenter,
    minHeight: 'calc(100vh - 30px - 61px)',
    padding: '0 30px 0 30px',
    // boxSizing: 'border-box',
    [theme.breakpoints.down('sm')]: {
      padding: '0 20px 0 20px',
    },
    [theme.breakpoints.up('xl')]: {
      minHeight: '75rem',
    },
  },
  heroText: {
    ...theme.globals.containerYCenter,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 30,
    },
  },
  title: {
    ...theme.typography.title,
    color: theme.palette.primary.main,
    fontWeight: 500,
    textAlign: 'left',
    [theme.breakpoints.down('sm')]: {
      fontSize: 24,
    },
  },
  titleHighlight: {
    fontWeight: 700,
  },
  phone: {
    width: '60%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  heroPhone: {
    ...theme.globals.containerYCenter,
  },
  background: {
    position: 'absolute',
    margin: '100px auto',
    maxWidth: "2000px",
    width: '100%',
    zIndex: -1,
  },
  ball1: {
    position: 'absolute',
    bottom: -100,
    left: '40%',
    width: 300,
    zIndex: -1,
    [theme.breakpoints.down('sm')]: {
      bottom: 0,
      width: 100,
    },
  },
  ball2: {
    position: 'absolute',
    bottom: -100,
    left: '60%',
    width: 200,
    zIndex: -1,
    [theme.breakpoints.down('sm')]: {
      bottom: 0,
      width: 90,
    },
    
  },
});

function HeroSection() {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <img src={background} alt='background' className={classes.background} />
      <motion.img
        src={ball2}
        alt='circle'
        className={classes.ball2}
        animate={{ scale: [1, .95, 1] }}
        transition={growTransition}
      />
      <motion.img
        src={ball1}
        alt='circle'
        className={classes.ball1}
        animate={{ scale: [1, 1.05, 1] }}
        transition={growTransition}
      />
      <Grid container>
        <Grid item xs={12} sm={6} className={classes.heroText}>
          <h1 className={classes.title}>
            <span className={classes.titleHighlight}>Conversaciones</span>{' '}
            automatizadas, inteligentes y fáciles de usar
          </h1>
        </Grid>
        <Grid item xs={12} sm={6} className={classes.heroPhone}>
          <motion.img src={heroPhone} alt='phone' className={classes.phone}  whileHover={{ scale: 1.02 }}
            transition={{ type: 'spring', stiffness: 100 }}/>
        </Grid>
      </Grid>
    </div>
  );
}

export default HeroSection;
