export const MenuItems = [
  {
    title: "Características",
    link: "#caracteristicas",
  },
  {
    title: "Planes",
    link: "#planes",
  },
  {
    title: "Contacto",
    link: "#contacto",
  },
];
