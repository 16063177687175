import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { motion } from 'framer-motion';
import wave from '../../../resources/images/background/bottom-wave.svg'

const useStyles = makeStyles({
  wave: {
    zIndex: '10',
    width: '100%',
    marginBottom: -5,
  },
});

function FooterWave() {
  const classes = useStyles();

  return (
    <motion.img
      src={wave}
      alt='icon'
      className={classes.wave}
    />
  );
}

export default FooterWave;
