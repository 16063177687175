import React from 'react';
import { makeStyles } from '@material-ui/styles';
import theme from '../../styles';
import { Grid } from '@material-ui/core';
import background from '../../resources/images/background/dual-wave.svg';
import Card from '../Card';
import CardMobile from '../CardMobile';
import { useWindowSize } from '../useWindowSize.js';

const useStyles = makeStyles({
  container: {
    ...theme.globals.containerYFlexstartLeft,
    minHeight: 'calc(100vh - 30px - 61px)',
    paddingTop: 100,
    [theme.breakpoints.up('xl')]: {
      minHeight: '75rem',
    },
  },
  titleContainer: {
    ...theme.globals.containerYFlexstartLeft,
    padding: '0 30px 0 30px',
    [theme.breakpoints.down('sm')]: {
      padding: '0 20px 0 20px',
    },
  },
  title: {
    ...theme.typography.title,
    color: theme.palette.primary.main,
    fontWeight: 700,
    textAlign: 'left',
    marginBottom: 30,
    [theme.breakpoints.down('sm')]: {
      fontSize: 24,
    },
  },
  background: {
    position: 'absolute',
    margin: '300px auto',
    maxWidth: "2000px",
    width: '100%',
    zIndex: -1,
    marginLeft: -30,
    [theme.breakpoints.down('sm')]: {
      marginLeft: -20,
    },
  },
  cardContainer: {
    ...theme.globals.containerXSpaceevenly,
    alignItems: 'stretch',
  },
  cardMobileContainer: {
    ...theme.globals.containerXFlexstart,
    overflowX: 'scroll',
    alignItems: 'stretch',
  },
});

function PlansSection() {
  const classes = useStyles();
  const size = useWindowSize();

  return (
      <div className={classes.container} id='planes'>
        <div className={classes.titleContainer}>

        <img src={background} alt='background' className={classes.background} />
        <h1 className={classes.title}>Planes</h1>
        </div>
        {size.width < 900 ? (
          <div className={classes.cardMobileContainer}>
            <CardMobile
              title='Entrada'
              content1='Campañas'
              content2='Interacciones'
              content3='WhatsApp'
              content4='Email'
            ></CardMobile>
            <CardMobile
              title='Avanzado'
              highlight='Todo de entrada'
              content1='Chatbot'
              content2='Citas'
              content3='Calendario'
              main
            ></CardMobile>
            <CardMobile
              title='Ilimitado'
              highlight='Todo de avanzado'
              content1='Resultados de laboratorio'
              content2='SMS'
              content3='Mensajes personalizados'
            ></CardMobile>
          </div>
        ) : (
          <Grid container className={classes.cardContainer}>
            <Card
              title='Entrada'
              content1='Campañas'
              content2='Interacciones'
              content3='WhatsApp'
              content4='Email'
            ></Card>
            <Card
              title='Avanzado'
              highlight='Todo de entrada'
              content1='Chatbot'
              content2='Citas'
              content3='Calendario'
              main
            ></Card>
            <Card
              title='Ilimitado'
              highlight='Todo de avanzado'
              content1='Resultados de laboratorio'
              content2='SMS'
              content3='Mensajes personalizados'
            ></Card>
          </Grid>
        )}
      </div>
  );
}

export default PlansSection;
