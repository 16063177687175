import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#003D7D',
    },
    secondary: {
      main: '#00CEBC',
    },
    primaryBackground: {
      main: '#174E87',
    },
    secondaryBackground: {
      main: '#2ED3C4',
    },
    white: { main: '#FFFFFF' },
    gray: { main: '#434343' },
    black: { main: '#000000' },
    darkBlue: { main: '#091F2E' },
    lightBlue: { main: '#2E5A6D' },
    darkGreen: { main: '#0E300C' },
    lightGreen: { main: '#485B29' },
    darkRed: { main: '#914919' },
    lightRed: { main: '#BA5A47' },
    yellow: { main: '#FBB03B' },
    orange: { main: '#D6752D' },
    beige: { main: '#74E7DF' },
  },
  typography: {
    heading1: {
      fontFamily: 'Montserrat',
      fontSize: 80,
      fontWeight: 700,
    },
    heading2: {
      fontFamily: 'Montserrat',
      fontSize: 60,
      fontWeight: 700,
    },
    heading3: {
      fontFamily: 'Montserrat',
      fontSize: 30,
      fontWeight: 700,
    },
    heading4: {
      fontFamily: 'Montserrat',
      fontSize: 24,
      fontWeight: 700,
    },
    largeText: {
      fontFamily: 'Montserrat',
      fontSize: 24,
      fontWeight: 400,
    },
    normalText: {
      fontFamily: 'Montserrat',
      fontSize: 16,
      fontWeight: 400,
    },
    title: {
      fontFamily: 'Montserrat',
      fontSize: 48,
      fontWeight: 600,
      textTransform: 'none',
      color: '#FFF',
      padding: 0,
      margin: 0,
    },
    subtitle: {
      fontFamily: 'Montserrat',
      fontSize: 20,
      fontWeight: 600,
      textTransform: 'none',
      color: '#FFF',
      padding: 0,
      margin: 0,
    },
    counter: {
      fontFamily: 'Montserrat',
      fontSize: 60,
      color: 'white',
    },
    counterLabel: {
      fontFamily: 'Montserrat',
      fontSize: 20,
      fontWeight: 500,
      color: 'white',
    },
    menuItems: {
      fontFamily: 'Montserrat',
      fontSize: 24,
      fontWeight: 400,
      textTransform: 'none',
      textDecoration: 'none',
      color: '#FFF',
    },
    button: {
      fontFamily: 'Montserrat',
      fontWeight: 500,
      fontSize: 16,
      textTransform: 'none',
      textDecoration: 'none',
      color: '#FFF',
    },
    input: {
      fontFamily: 'Montserrat',
      fontSize: 16,
      fontWeight: 500,
      textTransform: 'none',
      textDecoration: 'none',
      color: '#FFF',
    },
    text: {
      fontFamily: 'Montserrat',
      fontSize: 16,
      fontWeight: 500,
      textTransform: 'none',
      textDecoration: 'none',
      color: '#FFF',
    },
  },
  globals: {
    container: {
      width: '100vw',
      minHeight: '100vh',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
      boxSizing: 'border-box',
      backgroundColor: '#000000',
      padding: '20px 90px',
    },
    screenContainer: {
      width: '100vw',
      minHeight: '100vh',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
      boxSizing: 'border-box',
      backgroundColor: '#000000',
      padding: '20px 90px',
    },
    containerColumnCenterCenterFullscreen: {
      width: '100vw',
      minHeight: '100vh',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      boxSizing: 'border-box',
      padding: '20px 90px',
    },
    containerYCenterFullscreen: {
      width: '100vw',
      minHeight: '100vh',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      boxSizing: 'border-box',
      padding: '20px 90px',
    },
    containerYFlexstartFullscreen: {
      width: '100vw',
      minHeight: '100vh',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      boxSizing: 'border-box',
      padding: '20px 90px',
    },
    containerYFlexstartLeftFullscreen: {
      width: '100vw',
      minHeight: '100vh',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      boxSizing: 'border-box',
      padding: '20px 90px',
    },
    containerYSpacebetweenFullscreen: {
      width: '100vw',
      minHeight: '100vh',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
      boxSizing: 'border-box',
      padding: '20px 90px',
    },
    containerXSpacebetweenFullscreen: {
      width: '100vw',
      minHeight: '100vh',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      boxSizing: 'border-box',
      padding: '20px 90px',
    },
    containerColumnTopLeft: {
      width: '100%',
      minHeight: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      boxSizing: 'border-box',
    },
    containerColumnCenterLeft: {
      width: '100%',
      minHeight: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'flex-start',
      boxSizing: 'border-box',
    },
    containerRowCenterLeft: {
      width: '100%',
      minHeight: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'left',
      alignItems: 'center',
      boxSizing: 'border-box',
    },
    containerColumnTopCenter: {
      width: '100%',
      minHeight: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'flex-start',
      boxSizing: 'border-box',
    },
    containerYCenter: {
      width: '100%',
      minHeight: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      boxSizing: 'border-box',
    },
    containerYCenterRight: {
      width: '100%',
      minHeight: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      alignItems: 'flex-end',
      boxSizing: 'border-box',
    },
    containerYFlexstart: {
      width: '100%',
      minHeight: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'center',
      boxSizing: 'border-box',
    },
    containerYSpacebetween: {
      width: '100%',
      minHeight: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
      boxSizing: 'border-box',
    },
    containerYFlexstartLeft: {
      width: '100%',
      minHeight: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      boxSizing: 'border-box',
    },
    containerYFlexendRight: {
      width: '100%',
      minHeight: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      alignItems: 'end',
      boxSizing: 'border-box',
    },
    containerXCenter: {
      width: '100%',
      minHeight: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      boxSizing: 'border-box',
    },
    containerXFlexend: {
      width: '100%',
      minHeight: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      alignItems: 'center',
      boxSizing: 'border-box',
    },
    containerXSpacebetween: {
      width: '100%',
      minHeight: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      boxSizing: 'border-box',
    },
    containerXSpaceevenly: {
      width: '100%',
      minHeight: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-evenly',
      alignItems: 'center',
      boxSizing: 'border-box',
    },
    containerXFlexstart: {
      width: '100%',
      minHeight: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
      boxSizing: 'border-box',
    },
    iconContainer: {
      width: '100%',
      height: '100%',
      // display: 'flex',
      // flexDirection: 'column',
      // justifyContent: 'flex-end',
      // alignItems: 'flex-end',
      // boxSizing: 'border-box',
    },
    inputContainer: {
      width: '100vw',
      minHeight: '100vh',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
      boxSizing: 'border-box',
      backgroundColor: '#000000',
      padding: '20px 50px',
    },
    leftAlign: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
      padding: '100px',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
});

export default theme;
