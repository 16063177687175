import React from 'react';
import HeroSection from '../../components/HeroSection';
import FeaturesSection from '../../components/FeaturesSection';
import PlansSection from '../../components/PlansSection';
import ContactSection from '../../components/ContactSection';
// import * as Scroll from 'react-scroll';
// import {
//   Link,
//   Button,
//   Element,
//   Events,
//   animateScroll as scroll,
//   scrollSpy,
//   scroller,
// } from 'react-scroll';

function Home() {
  return (
    <div 
    // style={{ overflowX: 'hidden'}}
    >
      <HeroSection />
      <FeaturesSection />
      <PlansSection />
      <ContactSection />
    </div>
  );
}

export default Home;
