import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import emailjs from 'emailjs-com';
import theme from '../../styles';
import TextareaAutosize from 'react-textarea-autosize';
import Swal from 'sweetalert2';
import Recaptcha from 'react-google-invisible-recaptcha';
import './index.css';
import ball from '../../resources/images/background/ball-duo.svg';

const useStyles = makeStyles({
  container: {
    ...theme.globals.containerYCenter,
    marginBottom: 50,
    paddingTop: 100,
    padding: '0 30px 0 30px',
    // boxSizing: 'border-box',
    [theme.breakpoints.down('sm')]: {
      padding: '100px 50px 20px 50px',
    },
    [theme.breakpoints.up('xl')]: {
      minHeight: '75rem',
    },
  },
  titleContainer: {
    ...theme.globals.containerXSpacebetween,
  },
  title: {
    ...theme.typography.title,
    fontWeight: 700,
    color: theme.palette.primary.main,
    textAlign: 'left',
    marginBottom: 30,
    [theme.breakpoints.down('sm')]: {
      fontSize: 24,
    },
  },
  input: {
    ...theme.typography.text,
    fontWeight: 600,
    backgroundColor: 'transparent',
    color: theme.palette.primary.main,
    padding: '16px',
    cursor: 'pointer',
    border: `4px solid ${theme.palette.primary.main}`,
    boxSizing: 'border-box',
    width: '100%',
    textAlign: 'left',
    margin: '10px',
    borderRadius: '20px',
    [theme.breakpoints.up('lg')]: {
      padding: '10px 100px 10px 50px',
    },
    '&:focus': {
      outline: 'none !important',
    },
    '&::placeholder': {
      color: theme.palette.gray.main,
    },
  },
  inputMessage: {
    ...theme.typography.text,
    fontWeight: 600,
    backgroundColor: 'transparent',
    color: theme.palette.primary.main,
    cursor: 'pointer',
    border: `4px solid ${theme.palette.primary.main}`,
    boxSizing: 'border-box',
    width: '100%',

    textAlign: 'left',

    margin: '10px',
    padding: '20px',
    resize: 'none',
    borderRadius: '20px',
    [theme.breakpoints.up('lg')]: {
      padding: '10px 300px 10px 50px',
    },
    '&:focus': {
      outline: 'none !important',
    },
    '&::placeholder': {
      color: theme.palette.gray.main,
    },
    '&::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '&::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '&[type=number]': {
      '-moz-appearance': 'textfield',
    },
  },
  button: {
    backgroundColor: theme.palette.primary.main,
    borderBox: 'border-box',
    width: '100%',
    color: theme.palette.white.main,
    cursor: 'pointer',
    border: `2px solid ${theme.palette.primary.main}`,
    fontFamily: 'Poppins',
    fontWeight: 500,
    textAlign: 'center',
    fontSize: '16px',
    margin: '10px',
    padding: '10px',
    borderRadius: '20px',

    [theme.breakpoints.up('lg')]: {
      padding: '10px 150px 10px 150px',
    },
  },
  formContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  ball: {
    position: 'absolute',
    right: 0,
    zIndex: -1,
    [theme.breakpoints.down('sm')]: {
      width: 100,
    },
    [theme.breakpoints.up('xl')]: {
      position: "static"
    },
  },
});

const ContactForm = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [company, setCompany] = useState('');
  const [message, setMessage] = useState('');
  const [recaptcha, setRecaptcha] = useState(true);
  const refRecaptcha = React.useRef(null);

  const classes = useStyles();

  const sendEmail = (e) => {
    e.preventDefault();

    if (name && email && phone && company && message) {
      refRecaptcha.current.callbacks.execute();
      if (recaptcha === true) {
        emailjs
          .sendForm(
            'service_jx3hjhq',
            'template_46a1u8q',
            e.target,
            'user_wvjQ22nMrGRgnrlPEWvvE'
          )
          .then(
            (result) => {
              console.log(result.text);
            },
            (error) => {
              console.log(error.text);
            }
          );
        resetFields();
        Swal.fire({
          icon: 'success',
          title: '¡Gracias!',
          text:
            'Tu mensaje ha sido enviado correctamente. Nos comunicaremos contigo por e-mail lo antes posible',
          confirmButtonColor: theme.palette.lightGreen.main,
        });
      }
    } else {
      refRecaptcha.current.callbacks.execute();
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Por favor llena todos los campos',
        confirmButtonColor: '#DD6B55',
      });
    }
  };

  const resetFields = () => {
    setName('');
    setEmail('');
    setPhone('');
    setCompany('');
    setMessage('');
  };

  return (
    <div className={classes.container} id='contacto'>
      <div className={classes.titleContainer}>
        {/* <a id='contacto'> */}
        <h1 className={classes.title}>{'Contáctanos'}</h1>{' '}
        <img src={ball} alt='circle' className={classes.ball} />
        {/* </a> */}
      </div>
      <form onSubmit={(e) => sendEmail(e)}>
        <div className={classes.formContainer}>
          <input
            type='text'
            placeholder='Nombre'
            name='name'
            value={name}
            onChange={(e) => setName(e.target.value)}
            className={classes.input}
          ></input>
          <input
            type='email'
            placeholder='E-mail'
            name='email'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className={classes.input}
          ></input>
          <input
            type='number'
            placeholder='Teléfono'
            name='phone'
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            className={classes.input}
          ></input>
          <input
            type='text'
            placeholder='Empresa'
            name='company'
            value={company}
            onChange={(e) => setCompany(e.target.value)}
            className={classes.input}
          ></input>
          <TextareaAutosize
            multiline
            placeholder='Mensaje'
            minRows={8}
            name='message'
            maxWidth='100px'
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            className={classes.inputMessage}
          ></TextareaAutosize>
          <input type='submit' value='Enviar' className={classes.button} />
          <Recaptcha
            ref={refRecaptcha}
            sitekey='6Lehp4ofAAAAADjh9QCOTsu-d28sJgh37dpTp306'
            onResolved={() => setRecaptcha(true)}
          />
        </div>
      </form>
    </div>
  );
};

export default ContactForm;
