import React from 'react';
import { makeStyles } from '@material-ui/styles';
import logoNotisana from '../../resources/logo/notisana-logo-inverse.svg';
import logoSX4 from '../../resources/logo/sx4-logo-inverse.svg';
import theme from '../../styles';
// import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin, faFacebook } from '@fortawesome/free-brands-svg-icons';
import FooterWave from './FooterWave';

const useStyles = makeStyles({
  faIcons: {
    zIndex: '1',
    color: 'white',
  },
  logoContainer: {
    [theme.breakpoints.down('sm')]: {
      ...theme.globals.containerXCenter,
    },
  },
  logoNotisana: {
    // width: '175px',
    [theme.breakpoints.down('sm')]: {
      width: '70%',
      alignSelf: 'center',
    },
  },
  logoSX4: {
    // width: '175px',
    marginLeft: 20,
    [theme.breakpoints.down('sm')]: {
      width: '70%',
      alignSelf: 'center',
    },
  },
  menuItem: {
    ...theme.typography.menuItems,
    margin: '0 15px',
    color: '#FFF',
    fontFamily: 'Poppins',
    fontWeight: 400,
    textAlign: 'center',
    fontSize: '16px',

    [theme.breakpoints.down('sm')]: {
      // display: 'none',
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: '18px',
    },
    '&:hover': {
      color: theme.palette.secondary.main,
    },
  },
  footerContainer: {
    ...theme.globals.containerXSpacebetween,
    // display: "grid",
    // gridTemplateColumns: ".5fr 1.5fr 1.5fr .5fr",
    padding: '20px 90px',
    zIndex: '2',
    backgroundColor: theme.palette.primary.main,
    // alignItems: "center",
    [theme.breakpoints.down('sm')]: {
      ...theme.globals.containerYCenter,
      padding: '20px 0',
    },
  },
  containerXCenter: {
    ...theme.globals.containerXCenter,
    padding: 20,
  },
  containerXSpacebetween: {
    ...theme.globals.containerXSpacebetween,
    justifyContent: 'space-evenly',
    padding: 20,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  containerXFlexend: {
    ...theme.globals.containerXFlexend,
    padding: '20px 0 20px 20px',
    [theme.breakpoints.down('sm')]: {
      ...theme.globals.containerXCenter,
    },
  },
  linkContainer: {
    ...theme.globals.containerXFlexstart,
  },
});

function Footer(color) {
  const classes = useStyles();

  // const [menuOpen, setMenuOpen] = useState(false);

  return (
    <div
      style={{
        backgroundColor: `${color.color}`,
        maxWidth: '2000px',
        margin: '0 auto',
      }}
    >
      <FooterWave />
      <div container className={classes.footerContainer}>
        <div className={classes.linkContainer}>
          <a
            href='/'
            title='Regresar a inicio'
            className={classes.logoContainer}
          >
            <img
              src={logoNotisana}
              className={classes.logoNotisana}
              alt='logo'
            />
          </a>
          <a
            href='https://www.strategix4.com/'
            target='_blank'
            rel='noopener noreferrer'
            title='Regresar a inicio'
            className={classes.logoContainer}
          >
            <img src={logoSX4} className={classes.logoSX4} alt='logo' />
          </a>
        </div>

        <div className={classes.containerXFlexend}>
          <a
            href={'https://www.facebook.com/strategix4'}
            className={classes.menuItem}
            target='_blank'
            rel='noopener noreferrer'
            title='Notisana Facebook'
          >
            <FontAwesomeIcon icon={faFacebook} className='faIcons' size='lg' />
          </a>
          <a
            href={'https://www.linkedin.com/company/strategix4/'}
            className={classes.menuItem}
            target='_blank'
            rel='noopener noreferrer'
            title='Notisana Linkedin'
          >
            <FontAwesomeIcon icon={faLinkedin} className='faIcons' size='lg' />
          </a>
        </div>
      </div>
    </div>
  );
}

export default Footer;
