import React, { useState } from "react";
import { makeStyles } from "@material-ui/styles";
import logo from '../../resources/logo/notisana-logo.svg';
import theme from '../../styles';
import Drawer from '@mui/material/Drawer';
import MenuIcon from "@material-ui/icons/Menu";
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { MenuItems } from "./MenuItems";

const useStyles = makeStyles({
  navbar: {
    position: "sticky",
    // position: "fixed",
    zIndex: "1000",
    top: "0",
    boxSizing: "border-box",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: '30px 30px 0 30px',
  },
  menuContainerLeft: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  menuContainerRight: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  logoContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      ...theme.globals.containerXCenter,
    },
  },
  logo: {
    width: "200px",

    // filter: "grayscale(9)",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  menuItem: {
    ...theme.typography.menuItems,
    margin: "0px 30px",
    color: theme.palette.primary.main,
    textShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)',
    fontFamily: "Montserrat",
    fontWeight: 700,
    textAlign: "center",

    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    "&:hover": {
      color: theme.palette.secondary.main,
    },
  },
  hamburgerButton: {
    display: "none",
    color: theme.palette.primary.main,
    backgroundColor: "transparent",
    border: "none",
    [theme.breakpoints.down("sm")]: {
      padding: "20px 0",
      display: "flex",
    },
  },
  hamburgerMenuContainer: {
    width: "100%",
    height: "auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "Montserrat",
    fontWeight: 400,
    textAlign: "center",
    fontSize: "16px",
    padding: "20px",
    boxSizing: "border-box",
    background: theme.palette.primary.main,
  },
  hamburgerMenuItem: {
    ...theme.typography.menuItems,
    textShadow: '0px 0px 10px rgba(0, 0, 0, 0.5)',
    margin: "0px 30px",
    fontFamily: "Montserrat",
    fontWeight: 400,
    textAlign: "center",
    fontSize: "16px",
    padding: "20px",
  },
  containerXSpacebetween: {
    ...theme.globals.containerXSpacebetween,
  },
});

function Navbar() {
  const classes = useStyles();

  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <div className={classes.navbar}>
      <Drawer open={menuOpen} anchor="top">
        <ClickAwayListener onClickAway={() => setMenuOpen(false)}>
          <div className={classes.hamburgerMenuContainer}>
            {MenuItems.map((item, index) => {
              return (
                <a href={item.link} className={classes.hamburgerMenuItem}>
                  {item.title}
                </a>
              );
            })}
          </div>
        </ClickAwayListener>
      </Drawer>
      <div className={classes.containerXSpacebetween}>
        <div xs={12} sm={4} item>
          <a href="/" className={classes.logoContainer}>
            <img src={logo} className={classes.logo} alt="logo" />
          </a>
        </div>
        <div xs={8} item className={classes.menuContainerRight}>
          {MenuItems.map((item, index) => {
            return (
              <a href={item.link} className={classes.menuItem}>
                {item.title}
              </a>
            );
          })}
          <button
            type="button"
            onClick={() => setMenuOpen(true)}
            className={classes.hamburgerButton}
          >
            <MenuIcon />
          </button>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
