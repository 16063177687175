import React from 'react';
import { makeStyles } from '@material-ui/styles';
import theme from '../../styles';

const useStyles = makeStyles({
  container: {
    maxWidth: "2000px",
    [theme.breakpoints.up('xl')]: {
      margin: "0 auto",
    },
  },
});

function Navbar(props) {
  const classes = useStyles();

  return <div className={classes.container}>{props.children}</div>;
}

export default Navbar;
