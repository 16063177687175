import React from 'react';
import { makeStyles } from '@material-ui/styles';
import theme from '../../styles';
import background from '../../resources/images/background/features-wave.svg';
import { useWindowSize } from '../useWindowSize.js';
import ball1 from '../../resources/images/background/ball-secondary.svg';
import ball2 from '../../resources/images/background/ball-primary-half.svg';
import { motion } from 'framer-motion';
import growTransition from '../transition';

const useStyles = makeStyles({
  container: {
    ...theme.globals.containerYFlexstartLeft,
    minHeight: 'calc(100vh - 30px - 61px)',
    paddingTop: 100,
    padding: '0 30px 0 30px',
    [theme.breakpoints.down('sm')]: {
      padding: '0 20px 0 20px',
      minHeight: 'auto',
    },
    [theme.breakpoints.up('xl')]: {
      minHeight: '75rem',
    },
  },
  heroText: {
    ...theme.globals.containerYCenter,
  },
  title: {
    ...theme.typography.title,
    color: theme.palette.primary.main,
    fontWeight: 700,
    textAlign: 'left',
    [theme.breakpoints.down('sm')]: {
      fontSize: 24,
    },
  },
  background: {
    position: 'absolute',
    margin: '300px auto',
    maxWidth: "2000px",
    width: '100%',
    zIndex: -1,
    marginLeft: -30,
    [theme.breakpoints.down('sm')]: {
      marginLeft: -20,
    },
  },
  mobileContainer: {
    ...theme.globals.containerYFlexstart,
    padding: '30px 0',
  },
  text: {
    ...theme.typography.subtitle,
    color: theme.palette.primary.main,
    padding: '30px 0',
  },
  ball1: {
    position: 'absolute',
    top: '170vh',
    left: '10%',
    width: 170,
    zIndex: -1,
    [theme.breakpoints.down('sm')]: {
      top: '150vh',
      left: '5%',
      width: 100,
    },
    [theme.breakpoints.up('xl')]: {
      position: "static"
    },
  },
  ball2: {
    position: 'absolute',
    top: '170vh',
    right: '0%',
    width: 200,
    zIndex: -1,
    [theme.breakpoints.down('sm')]: {
      top: '150vh',
      width: 120,
    },
    [theme.breakpoints.up('xl')]: {
      display: "none",
    },
  },
});

function FeaturesSection() {
  const classes = useStyles();
  const size = useWindowSize();

  return (
    <div className={classes.container} id='caracteristicas'>
      <img
        src={ball2}
        alt='circle'
        className={classes.ball2}
      />
      <motion.img
        src={ball1}
        alt='circle'
        className={classes.ball1}
        animate={{ scale: [1, 1.05, 1] }}
        transition={growTransition}
      />
      <h1 className={classes.title}>Características</h1>
      {size.width > 900 ? (
        <img src={background} alt='background' className={classes.background} />
      ) : (
        <div className={classes.mobileContainer}>
          <text className={classes.text}>Citas</text>
          <text className={classes.text}>Dudas frecuentes</text>
          <text className={classes.text}>Resultados médicos</text>
          <text className={classes.text}>Recordatorios</text>
        </div>
      )}
    </div>
  );
}

export default FeaturesSection;
