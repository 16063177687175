import React from 'react';
import { makeStyles } from '@material-ui/styles';
import theme from '../../styles';

const useStyles = makeStyles({
  container: {
    ...theme.globals.containerYFlexstart,
    minHeight: '70vh',
    borderRadius: 30,
    backgroundColor: (props) =>
      props.main ? theme.palette.secondaryBackground.main : theme.palette.primaryBackground.main,
    padding: '30px',
    gridAutoRows: '1fr',
    margin: 20,
  },
  featureContainer: {
    ...theme.globals.containerYSpacebetween,
    padding: '30px 0',
  },
  title: {
    ...theme.typography.title,
    color: (props) =>
      props.main ? theme.palette.primary.main : theme.palette.white.main,
    fontSize: 24,
    fontWeight: 700,
    textAlign: 'left',
  },
  highlightContainer: {
    ...theme.globals.containerXCenter,
    minHeight: 'auto',
    textAlign: 'center',
    width: '100%',
    backgroundColor: (props) =>
      props.main ? theme.palette.primaryBackground.main : theme.palette.secondaryBackground.main,
    borderRadius: 30,
    padding: '10px 20px',
  },
  highlightText: {
    ...theme.typography.text,
    fontSize: 20,
    fontWeight: 700,
    color: theme.palette.white.main,
  },
  text: {
    ...theme.typography.text,
    fontSize: 20,
    fontWeight: 700,
    textAlign: 'center',
    color: (props) =>
      props.main ? theme.palette.primary.main : theme.palette.white.main,
  },
});

function Card(props) {
  const classes = useStyles(props);

  return (
    <div className={classes.container}>
      <h1 className={classes.title}>{props.title}</h1>
      <div className={classes.featureContainer}>
      {props.highlight ? (
        <div className={classes.highlightContainer}>
          <text className={classes.highlightText}>{props.highlight}</text>
        </div>
      ) : null}
      {props.content1 ? (
        <text className={classes.text}>{props.content1}</text>
      ) : null}
      {props.content2 ? (
        <text className={classes.text}>{props.content2}</text>
      ) : null}
      {props.content3 ? (
        <text className={classes.text}>{props.content3}</text>
      ) : null}
      {props.content4 ? (
        <text className={classes.text}>{props.content4}</text>
      ) : null}
      </div>
    </div>
  );
}

export default Card;
